import React, { useState } from 'react';
import './LoanCalculator.css';

function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState('');
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [loanTermYears, setLoanTermYears] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);

  const calculateMonthlyPayment = () => {
    const P = parseFloat(loanAmount);
    const r = parseFloat(annualInterestRate) / 100 / 12;
    const n = parseFloat(loanTermYears) * 12;

    if (isNaN(P) || isNaN(r) || isNaN(n) || P <= 0 || r <= 0 || n <= 0) {
      setMonthlyPayment('Please enter positive numbers.');
      return;
    }

    const M = (P * r) / (1 - Math.pow(1 + r, -n));

    setMonthlyPayment(`Monthly Payment: $${M.toFixed(2)}`);

    // Generate Amortization Schedule
    let balance = P;
    let schedule = [];

    for (let i = 1; i <= n; i++) {
      const interestPayment = balance * r;
      const principalPayment = M - interestPayment;
      balance -= principalPayment;

      schedule.push({
        month: i,
        interestPayment: interestPayment.toFixed(2),
        principalPayment: principalPayment.toFixed(2),
        balance: balance.toFixed(2),
      });
    }

    setAmortizationSchedule(schedule);
  };

  return (
    <div className="loan-calculator container">
      <h1 className="text-center my-4">Loan Amortization Calculator</h1>

      <div className="form-group">
        <label htmlFor="loanAmount">Loan Amount ($):</label>
        <input
          id="loanAmount"
          type="number"
          className="form-control"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
          placeholder="e.g., 25000"
        />
      </div>

      <div className="form-group">
        <label htmlFor="annualInterestRate">Annual Interest Rate (%):</label>
        <input
          id="annualInterestRate"
          type="number"
          className="form-control"
          value={annualInterestRate}
          onChange={(e) => setAnnualInterestRate(e.target.value)}
          placeholder="e.g., 5"
        />
      </div>

      <div className="form-group">
        <label htmlFor="loanTermYears">Loan Term (years):</label>
        <input
          id="loanTermYears"
          type="number"
          className="form-control"
          value={loanTermYears}
          onChange={(e) => setLoanTermYears(e.target.value)}
          placeholder="e.g., 5"
        />
      </div>

      <button className="btn btn-primary" onClick={calculateMonthlyPayment}>
        Calculate Monthly Payment
      </button>

      {monthlyPayment && (
        <div className="result mt-4 alert alert-info">
          <pre>{monthlyPayment}</pre>
        </div>
      )}

      {amortizationSchedule.length > 0 && (
        <div className="schedule mt-5">
          <h2>Amortization Schedule</h2>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Month</th>
                <th>Interest</th>
                <th>Principal</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {amortizationSchedule.map((payment) => (
                <tr key={payment.month}>
                  <td>{payment.month}</td>
                  <td>${payment.interestPayment}</td>
                  <td>${payment.principalPayment}</td>
                  <td>${payment.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default LoanCalculator;
