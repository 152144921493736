// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
// src/index.js
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App'; // Import your main App component

// Render the app wrapped in the Router


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
