// src/components/ThemeToggle/ThemeToggle.js
import React, { useState, useEffect } from 'react';
import './ThemeToggle.css';

function ThemeToggle() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || 'light';
    setTheme(currentTheme);
    document.documentElement.setAttribute('data-theme', currentTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div id="theme-toggle" className="theme-toggle-switch" onClick={toggleTheme}>
      <div className="toggle-circle"></div>
      <span className="icon-sun">🌞</span>
      <span className="icon-moon">🌙</span>
    </div>
  );
}

export default ThemeToggle;
