// src/components/AppBlock/AppBlock.js
import React from 'react';
import { Link } from 'react-router-dom';
import './AppBlock.css';

function AppBlock({ id, link, imgSrc, title, comingSoon }) {
  return (
    <div className={`app-block ${comingSoon ? 'coming-soon' : ''}`} id={id}>
      <Link to={link}>
        <img src={imgSrc} alt={title} />
        <h2>{title}</h2>
      </Link>
    </div>
  );
}

export default AppBlock;
