// src/components/InterestCalculator.js
import React, { useState } from 'react';
import './InterestCalculator.css';

function InterestCalculator() {
  const [interestType, setInterestType] = useState('simple');
  const [principal, setPrincipal] = useState('');
  const [rate, setRate] = useState('');
  const [time, setTime] = useState('');
  const [compoundingFrequency, setCompoundingFrequency] = useState('annual');
  const [result, setResult] = useState(null);

  const calculateInterest = () => {
    const P = parseFloat(principal);
    const r = parseFloat(rate) / 100;
    const t = parseFloat(time);

    if (isNaN(P) || isNaN(r) || isNaN(t)) {
      setResult('Please enter valid numbers.');
      return;
    }

    let interestAmount;

    if (interestType === 'simple') {
      interestAmount = P * (1 + r * t);
    } else {
      let n;
      switch (compoundingFrequency) {
        case 'daily':
          n = 365;
          break;
        case 'monthly':
          n = 12;
          break;
        case 'quarterly':
          n = 4;
          break;
        case 'semiannually':
          n = 2;
          break;
        case 'annual':
          n = 1;
          break;
        default:
          n = 1;
      }
      interestAmount = P * Math.pow(1 + r / n, n * t);
    }

    const interestEarned = interestAmount - P;
    setResult(`Total Amount: $${interestAmount.toFixed(2)} \n Interest Earned: $${interestEarned.toFixed(2)}`);
  };

  return (
    <div className="container interest-calculator">
      <h1 className="text-center my-4">Interest Calculator</h1>

      <div className="interest-type form-check form-check-inline mb-4">
        <input
          className="form-check-input"
          type="radio"
          name="interestType"
          id="simpleInterest"
          value="simple"
          checked={interestType === 'simple'}
          onChange={(e) => setInterestType(e.target.value)}
        />
        <label className="form-check-label" htmlFor="simpleInterest">
          Simple Interest
        </label>

        <input
          className="form-check-input ml-3"
          type="radio"
          name="interestType"
          id="compoundInterest"
          value="compound"
          checked={interestType === 'compound'}
          onChange={(e) => setInterestType(e.target.value)}
        />
        <label className="form-check-label" htmlFor="compoundInterest">
          Compound Interest
        </label>
      </div>

      <div className="form-group">
        <label htmlFor="principal">Principal Amount:</label>
        <input
          id="principal"
          type="number"
          className="form-control"
          value={principal}
          onChange={(e) => setPrincipal(e.target.value)}
          placeholder="e.g., 1000"
        />
      </div>

      <div className="form-group">
        <label htmlFor="rate">Annual Interest Rate (%):</label>
        <input
          id="rate"
          type="number"
          className="form-control"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          placeholder="e.g., 5"
        />
      </div>

      <div className="form-group">
        <label htmlFor="time">Time (years):</label>
        <input
          id="time"
          type="number"
          className="form-control"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          placeholder="e.g., 2"
        />
      </div>

      {interestType === 'compound' && (
        <div className="form-group">
          <label htmlFor="compoundingFrequency">Compounding Frequency:</label>
          <select
            id="compoundingFrequency"
            className="form-control"
            value={compoundingFrequency}
            onChange={(e) => setCompoundingFrequency(e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="semiannually">Semiannually</option>
            <option value="annual">Annually</option>
          </select>
        </div>
      )}

      <button className="btn btn-primary" onClick={calculateInterest}>
        Calculate Interest
      </button>

      {result && (
        <div className="alert alert-info mt-4">
          <pre>{result}</pre>
        </div>
      )}
    </div>
  );
}

export default InterestCalculator;
