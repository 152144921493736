// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Use Routes instead of Switch in v6
import ThemeToggle from './components/ThemeToggle';
import './App.css';
import HomePage from './pages/HomePage';
// Import your pages
import InterestCalculator from './pages/InterestCalculator';
import LoanCalculator from './pages/LoanCalculator';

function App() {
  return (
    <div>
      <ThemeToggle />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/interest" element={<InterestCalculator />} />
        <Route path="/loan" element={<LoanCalculator />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

export default App;
