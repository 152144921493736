// src/pages/HomePage.js
import React from 'react';
import ThemeToggle from '../components/ThemeToggle';
import AppBlock from '../components/AppBlock';
import './HomePage.css'; // Optional: Create a CSS file for HomePage-specific styles

function HomePage() {
  return (
    <>
      <header>
        <h1>Welcome to Financial Master Tools- Automated Google builder</h1>
        <p>Select a tool below to begin:</p>
      </header>

      <main className="app-container">
        {/* App Blocks */}
        <AppBlock
          id="app1"
          link="/interest" // Route to Interest Calculator
          imgSrc="assets/images/interest_calculator.png" // Ensure correct path
          title="Interest Calculator"
        />
        <AppBlock
          id="app2"
          link="/loan" // Route to Loan Calculator
          imgSrc="assets/images/loan_calculator_new.png" // Ensure correct path
          title="Loan Calculator"
        />
        {/* Additional AppBlocks */}
        <AppBlock
          id="app3"
          link="/coming-soon" // Placeholder route
          imgSrc="assets/images/placeholder1.png"
          title="App 3 - Coming Soon"
          comingSoon={true}
        />
        <AppBlock
          id="app4"
          link="/coming-soon"
          imgSrc="assets/images/placeholder1.png"
          title="App 4 - Coming Soon"
          comingSoon={true}
        />
        <AppBlock
          id="app5"
          link="/coming-soon"
          imgSrc="assets/images/placeholder1.png"
          title="App 5 - Coming Soon"
          comingSoon={true}
        />
        <AppBlock
          id="app6"
          link="/coming-soon"
          imgSrc="assets/images/placeholder1.png"
          title="App 6 - Coming Soon"
          comingSoon={true}
        />
      </main>

      <ThemeToggle />

      <footer>
        <p>© 2024 Financial Master Tools</p>
      </footer>
    </>
  );
}

export default HomePage;
